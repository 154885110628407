import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import _ from "lodash"
import Download from "../images/download.svg"

export default function Home() {

  const q = useStaticQuery(graphql`
    {
      allTown2CogCsv {
        nodes {
          Town
          COG
        }
      }
    }
  `)

  const county2towns = _.groupBy(q.allTown2CogCsv.nodes, 'COG')

  return <Layout>

    <h3 className="f3">Find your town's housing data</h3>

    <div className="flex flex-wrap">

      <iframe src={"./map.html"} className="w-100 bw0" style={{height: '600px'}} title="Select town" />

    {/*
      Object.keys(county2towns).sort().map(county => <div key={'index-county-' + county} className="w-50 w-25-l">
        <h4 className="f4 mb0">{county}</h4>

        <ul className="list pl0 mt1">
        {
            county2towns[county].map(town => <li key={'index-county-list-' + town.Town}>
              <Link to={"/profile#" + town.Town} className="black dim no-underline lh-title">{town.Town}</Link>
            </li>)
          }
        </ul>
      </div>)
    */}

    </div>

    <h3 className="f3 mt5">See how counties (planning regions) compare to the state</h3>

    <p className="f5 measure-wide">
      Since Connecticut abolished county government in 1960, the Office of Policy and Management petitioned the
      Census Bureau to collect and report data be collected and reported at the nine Councils of Governments (COGs)
      instead of the county level. COGs oversee regional planning and programming so it was important to
      have data provided at that geographic level. More information can be found
      at <a href="https://www.ctdata.org/blog/geographic-resources-for-connecticuts-new-county-equivalent-geography" className="link blue hover-navy" target="_blank" rel="noreferrer">ctdata.org</a>.
    </p>

    <p className="f4 measure-wide lh-copy">
      {
        Object.keys(county2towns).sort().map(county =>
          <span key={county} className="dib w-100 link blue hover-navy">
            <Link
              key={county}
              to={'compare/#' + county + ',Connecticut'}
              className="link blue hover-navy"
            >
              {county}
            </Link>

            <a
              className="br3 ba b--green link black-80 ph1 f6 ml2 hover-bg-light-green"
              href={'https://housing-profiles.s3.amazonaws.com/2024/' + county + '.pdf'}>
                <img src={Download} alt="Download a PDF" /> .pdf
            </a>
          </span>
        )
      }
    </p>


    <h3 className="f3 mt5">Compare multiple geographies</h3>

    <p className="f4 measure-wide">
      <Link to="/compare" className="black underline dim">Use this
      comparison interface</Link> to compare multiple geographies,
      including towns, counties, and the state of Connecticut.
    </p>

    <h3 className="f3 mt5">What are Housing Data Profiles?</h3>
    <p className="f4 measure-wide">
      The Partnership for Strong Communities's Housing Data Profiles are a free
      resource to help Connecticut residents, developers, legislators, municipal
      officials, and others make data-informed decisions.
      Profiles are available for every town in the state, as well as each county,
      and the state as a whole.
    </p>

    <p className="f4 measure-wide">
      Housing Data Profiles offer data on an array of housing metrics across
      Connecticut, providing users with information on housing stock, income,
      race, age distribution of residents, housing characteristics (age of
      housing stock, number of single-family or multifamily homes,
      number of bedrooms in homes), housing costs and affordability,
      housing production and affordable units.
    </p>

  </Layout>

}